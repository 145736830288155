import { colors } from '@clientos/ui-toolkit'
import { ContextualMenu } from '@veneer/core'
import tokens from '@veneer/tokens'
import styled, { createGlobalStyle } from 'styled-components'

interface GlobalStyleProps {
  appMode: string | null
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  h2 {
    font-family: ${tokens.typography.family0};
    font-size: 22px;
  }
  h3 {
    font-family: ${tokens.typography.family0};
    font-size: 20px;
    line-height: 28px;
  }
  h4 {
    font-family: ${tokens.typography.family0};
    font-size: 17px;
  }
  h5 {
    font-family: ${tokens.typography.family0};
    font-size: 16px;
  }
  h6 {
    font-family: ${tokens.typography.family0};
    font-size: 15px;
    line-height: 20px;
  }
  body {
    font-family: ${tokens.typography.family0};
    font-size: 15px;
    height: 100vh;
    overflow: hidden;
    color: #4e4e4e;
  }
  p {
    font-family: ${tokens.typography.family0};
    font-size: 15px;
    color: #4e4e4e;
    line-height: 24px;
  }
  div[role="alert"] {
    margin-top: 40px;
  }

  .clientos-ui-toolkit-list-container {
    .clientos-ui-toolkit-list-container__start-icon {
      svg.rounded-icon {
        padding: 5px;
        color: white !important;
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }

  .clientos-ui-toolkit-empty-page__content {
    padding: 0 !important;
    ${({ appMode }) =>
      appMode === 'flyout' &&
      `
      background-color: white !important;
      padding: 0px !important;
    `}
  }

  img.rounded-icon {
    padding: 2px;
    color: white !important;
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`

export const StyledContextualMenu = styled(ContextualMenu)`
  width: auto !important;

  li:hover {
    background-color: ${colors.interfaceSurfaceElevataed};
  }

  div {
    &:nth-child(1) {
      min-width: 120px !important;
    }
  }
`
