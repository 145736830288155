import { IconChevronRight } from '@veneer/core'
import styled from 'styled-components'

export const MainContainer = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'container'
    'singleFooter';
`

export const ContentContainer = styled.div`
  padding: 0px 7px 40px 7px;
  grid-area: 'container';
  overflow: auto;
`

export const GenericCardContainerDesktop = styled.div`
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__description {
    font-size: 14px;
    line-height: 20px;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__label {
    font-size: 18px;
    line-height: 24px;
    color: #191a22 !important;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__end-content {
    align-self: center !important;
  }

  .clientos-ui-toolkit-list-container__end-content p {
    font-size: 18px !important;
    color: #292a35 !important;
  }
`

export const GenericCardContainerMobile = styled.div`
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__description {
    font-size: 12px;
    line-height: 16px;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__label {
    color: #191a22 !important;
    font-size: 16px;
    font-weight: 400;
  }

  .clientos-ui-toolkit-list-container
    > .clientos-ui-toolkit-list-container__divider {
    border-color: #edeefe !important;
    border-width: 1px;
    border-style: solid;
    margin-right: 20px;
    margin-left: 20px;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__item {
    gap: 16px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-items: end;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__start-content {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__end-content {
    align-self: center !important;
    max-width: none !important;
  }

  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__end-content
    p {
    font-size: 14px !important;
    line-height: 20px !important;
    color: #292a35;
  }
  .clientos-ui-toolkit-list-container
    .clientos-ui-toolkit-list-container__end-content
    svg {
    color: #212121;
  }
`

export const HeaderContainerMobile = styled.div`
  button.clientos-ui-toolkit-back-navigation-button {
    padding: 8px;
    height: 40px;
    width: 40px;
    span {
      display: grid !important;
      align-self: center;
    }
  }
  .vn-header__center-area {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-left: -28px;
    color: #292a35;
  }
`

export const PrinterSettingContent = styled.div<{ enable: boolean }>`
  display: flex;
  flex-shrink: 0;
  ${(props) => (props.enable ? 'cursor: no-drop;' : 'cursor: pointer;')}
`

export const ScrollbarContent = styled.div`
  .clientos-ui-toolkit-scrollbar
    > div
    > .clientos-ui-toolkit-scrollbar__content {
    padding-right: 0px !important;
  }
`

export const StyledIconChevronRight = styled(IconChevronRight)<{ id: string }>``
