// eslint-disable-next-line no-restricted-imports
import { AuthBrowserErrorType } from '@jarvis/jweb-core'
import { OptionInterface, useToast } from '@veneer/core'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from '../../../assets/locale'
import {
  createShortcut,
  generateCloudStorageLoginURL,
  getCloudStoragesList,
  updateShortcut
} from '../../../clients/shortcuts'
import CustomFooter from '../../../components/CustomFooter'
import DestinationsToggleList from '../../../components/DestinationsContentToggle'
import EmailForm from '../../../components/EmailForm'
import FolderListingModal from '../../../components/FolderListingModal'
import Loader from '../../../components/Loader'
import ModalDelete from '../../../components/ModalDelete'
import ModalDuplicateName from '../../../components/ModalDuplicateName'
import ModalEditAccountName from '../../../components/ModalEditAccountName'
import ModalRemoveAccess from '../../../components/ModalRemoveAccess'
import PrintOptions from '../../../components/PrintOptions'
import SaveOptions from '../../../components/SaveOptions'
import ShortcutName from '../../../components/ShortcutName'
import ShortcutSettingsForm from '../../../components/ShortcutSettingsForm'
import { useDestinationsContext } from '../../../contexts/destinations/DestinationsContext'
import { useEditContext } from '../../../contexts/edit/EditContext'
import { useSettingsContext } from '../../../contexts/settings/SettingsContext'
import { useUserContext } from '../../../contexts/users/UserContext'
import { colors, randomColor } from '../../../data/enum/colors'
import { getAddSaveOptions } from '../../../data/enum/menuOptions'
import { ocrFileTypes } from '../../../data/enum/ocrFileTypes'
import { PageProps } from '../../../data/schemas/app'
import { Connector, ConnectorLoginSpecs } from '../../../data/schemas/connector'
import { CopiesConfigOptionsList } from '../../../data/schemas/destinations'
import { FolderItem } from '../../../data/schemas/folders'
import { LocationState } from '../../../data/schemas/location'
import {
  ShortcutItem,
  SmartTask,
  SmartTaskConfig
} from '../../../data/schemas/shortcut'
import useTreeViewNodes from '../../../hooks/helpers/useTreeViewNodes'
import {
  determineOcrLanguage,
  getPrinterDefaultOcrLanguage,
  resolveAccountSaveDestination,
  resolveConnectorName,
  resolveFileTypeDetail,
  resolveProviderDetail,
  resolveTwoSidedDetail,
  toastMessages
} from '../../../modules/common/helpers'
import {
  isValidEmail,
  isValidShortcutTitle,
  resolveRepositoryType
} from '../../../modules/destinations/helpers'
import { flattenTree } from '../../../modules/destinations/treeViewMapping/helpers'
import {
  getAuthBrowserResponseError,
  openAuthBrowser
} from '../../../plugins/jweb/AuthBrowser'
import { sendUiEvent, uiData } from '../../../plugins/jweb/DataCollection'
import {
  ContentLoader,
  SettingsContainer,
  ShortcutNameContainer
} from './styles'
import {
  GenericCard,
  SectionListContainer,
  colors as colorsTokens,
  Modal
} from '@clientos/ui-toolkit'
import ModalSavedShortcut from 'src/components/ModalSavedShortcut'
import PageTemplate from 'src/pages/PageTemplate'

export type SelectedConnectorType = {
  currentConnectorId: string
  currentConnectorType: string
}

export const DestinationsDesktopMode = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()
  const { addToast } = useToast()

  const { settingsState, settingsActions } = useSettingsContext()
  const { destinationsState, destinationsActions } = useDestinationsContext()
  const { userContextState } = useUserContext()
  const { editState, editActions } = useEditContext()

  const [providersList, setProvidersList] = useState<Connector[]>([])
  const [authorizedProvidersList, setAuthorizedProvidersList] = useState<
    Connector[]
  >([])
  const [fetchProvidersListFailed, setFetchProvidersListFailed] =
    useState(false)
  const [newConnectorName, setNewConnectorName] = useState('')

  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [emailValue, setEmailValue] = useState(
    destinationsState.currentState.email.tos.join(', ')
  )
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [currentRepository, setCurrentRepository] = useState<Connector>()
  const [isCreatingShortcut, setIsCreatingShortcut] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showSavedShortcutModal, setShowSavedShortcutModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [savedShortcut, setSavedShortcut] = useState<ShortcutItem>()
  const [showDuplicateNameModal, setShowDuplicateNameModal] = useState(false)
  const [toggleEmailState, setToggleEmailState] = useState(false)
  const [togglePrinterState, setTogglePrinterState] = useState(false)
  const [toggleSaveState, setToggleSaveState] = useState(false)
  const [loadingShortcut, setLoadingShortcut] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [templateTriggered, setTemplateTriggered] = useState(false)
  const [checkboxSelected, setCheckboxSelected] = useState(false)
  const [showFileType, setShowFileType] = useState(false)
  const [loadingSignInButton, setLoadingSignInButton] = useState(false)
  const [duplicateNameDetected, setDuplicateNameDetected] = useState(false)
  const [updatingAccountsLoader, setUpdatingAccountsLoader] = useState(false)
  const [suggestionLoaded, setSuggestionLoaded] = useState(false)
  const isEmailOrSaveSet = toggleEmailState || toggleSaveState
  const [recentlyLoggedInConnector, setRecentLoggedInConnector] =
    useState<Connector>()
  const [editConnectorNameSuccess, setEditConnectorNameSuccess] =
    useState(false)

  const fallBackFolder = useRef<FolderItem>({
    id: 'HP app',
    name: 'HP app',
    type: 'folder',
    isValid: true
  })

  const {
    isLoadingFolders,
    folderTree,
    errorOnFetchFolder,
    onFolderListingOpen,
    onNodeExpand,
    cleanFolderTree,
    setErrorOnFetchFolder
  } = useTreeViewNodes()

  const toggleFolderListingModal = () => {
    setContextualInfo((prev) => ({
      ...prev,
      showModalFolderListing: !prev.showModalFolderListing
    }))
  }

  const handleDeleteFolder = (
    connectorId: string | undefined,
    folderId: string
  ) => {
    const currentConnectorFolderIndex = settingsState.foldersList.findIndex(
      (item) => item.connectorId === connectorId
    )
    settingsState.foldersList[currentConnectorFolderIndex].folders =
      settingsState.foldersList[currentConnectorFolderIndex].folders.filter(
        (item) => item.id !== folderId
      )
    settingsActions.updateFoldersList([...settingsState.foldersList])
  }

  const onClickSaveFolderModal = (nodeId: string) => {
    //Here I need to check if the user is using the default folder or Not
    if (nodeId !== settingsState.selectedFolder) {
      onNodeSelect(nodeId)
    }

    handleDeleteFolder(
      settingsState.currentConnectorId,
      fallBackFolder.current.id
    )

    settingsActions.updateEditSelectedFolder('')
    settingsActions.updateSelectedFolder('')
    toggleFolderListingModal()
  }

  const onClickCancelFolderModal = () => {
    settingsActions.updateSelectedFolder('')
    toggleFolderListingModal()
  }

  const onClickDeleteSelectedFolder = (
    providerItem: Connector,
    folderId: string
  ) => {
    handleDeleteFolder(providerItem.connectorId, folderId)
  }

  const onClickEditSelectedFolder = (
    providerItem: Connector,
    folderId?: string
  ) => {
    settingsActions.updateCurrentConnectorId(providerItem.connectorId!)
    settingsActions.updateCurrentConnectorType(providerItem.type)
    !!folderId && settingsActions.updateEditSelectedFolder(folderId)
    toggleFolderListingModal()
  }

  const onNodeSelect = (nodeId: string) => {
    const currentConnectorFoldersIndex = settingsState.foldersList.findIndex(
      (item) => item.connectorId === settingsState.currentConnectorId
    )

    const isFolderAlreadySelected = settingsState.foldersList[
      currentConnectorFoldersIndex
    ]?.folders.some((folder) => folder.id === nodeId)

    if (isFolderAlreadySelected) {
      if (
        settingsState.editSelectedFolder &&
        settingsState.editSelectedFolder !== nodeId &&
        !isFolderAlreadySelected
      ) {
        settingsState.foldersList[currentConnectorFoldersIndex].folders =
          settingsState.foldersList[
            currentConnectorFoldersIndex
          ].folders.filter((folder) => folder.id !== nodeId)
        settingsActions.updateFoldersList([...settingsState.foldersList])
      }
    } else {
      const selectedNode = flattenTree(settingsState.folderTree).find(
        (node) => node.id === nodeId
      )
      if (currentConnectorFoldersIndex < 0) {
        settingsState.foldersList.push({
          connectorId: settingsState.currentConnectorId!,
          folders:
            selectedNode?.name === fallBackFolder.current.name
              ? [selectedNode!]
              : [fallBackFolder.current, selectedNode!]
        })
        settingsActions.updateFoldersList([...settingsState.foldersList])
        return
      }

      if (
        settingsState.foldersList[currentConnectorFoldersIndex].folders[0]
          .name === fallBackFolder.current.name &&
        selectedNode?.name === fallBackFolder.current.name
      ) {
        settingsState.foldersList[currentConnectorFoldersIndex].folders[0] =
          selectedNode!
        settingsActions.updateFoldersList([...settingsState.foldersList])
        return
      }

      if (
        settingsState.editSelectedFolder &&
        settingsState.editSelectedFolder !== nodeId
      ) {
        settingsState.foldersList[currentConnectorFoldersIndex].folders =
          settingsState.foldersList[
            currentConnectorFoldersIndex
          ].folders.filter(
            (folder) => folder.id !== settingsState.editSelectedFolder
          )
      }
      settingsState.foldersList[currentConnectorFoldersIndex].folders =
        settingsState.foldersList[currentConnectorFoldersIndex].folders.concat(
          selectedNode!
        )
      settingsActions.updateFoldersList([...settingsState.foldersList])
    }
  }

  const [contextualInfo, setContextualInfo] = useState<{
    repositories: Connector[]
    connector?: Connector
    showModalRemoveAccess: boolean
    showModalFolderListing: boolean
    showModalEditName: boolean
  }>({
    repositories: [],
    connector: undefined,
    showModalRemoveAccess: false,
    showModalFolderListing: false,
    showModalEditName: false
  })

  const handleEmailToggle = () => {
    setToggleEmailState(!toggleEmailState)
  }

  const handlePrinterToggle = () => {
    setTogglePrinterState(!togglePrinterState)
  }

  const handleSaveToggle = () => {
    setToggleSaveState(!toggleSaveState)
  }

  const handleContextualMenuOptionClick = (
    providerItem: Connector,
    option: OptionInterface
  ) => {
    const { value } = option
    setCurrentRepository(providerItem)
    switch (value) {
      case 'remove-access':
        sendUiEvent(uiData.screenRemoveAccessDesktop)
        setContextualInfo((prev) => ({
          ...prev,
          connector: providerItem,
          repositories: [...authorizedProvidersList],
          showModalRemoveAccess: true
        }))
        break
      case 'add-destination':
        setContextualInfo((prev) => ({
          ...prev,
          showModalFolderListing: true
        }))
        settingsActions.updateCurrentConnectorId(providerItem.connectorId!)
        settingsActions.updateCurrentConnectorType(providerItem.type)
        settingsActions.updateEditSelectedFolder('')
        break
      case 'edit-account-name':
        setContextualInfo((prev) => ({
          ...prev,
          connector: providerItem,
          repositories: [...authorizedProvidersList],
          showModalEditName: true
        }))
        break
    }
  }

  const onSaveShortcut = (shortcut: ShortcutItem, isUpdate?: boolean) => {
    setSavedShortcut(shortcut)
    setShowSavedShortcutModal(true)
    setIsUpdate(isUpdate)
  }

  const onClickRemoveAccessButton = () => {
    setContextualInfo((prev) => ({
      ...prev,
      showModalRemoveAccess: !prev.showModalRemoveAccess
    }))
  }

  const onClickEditAcccountNameCancel = () => {
    setContextualInfo((prev) => ({
      ...prev,
      showModalEditName: !prev.showModalEditName
    }))
  }

  const onClickFileType = (_e: SyntheticEvent, value: string) => {
    settingsActions.updateFileType(value)
  }

  const onClickCancel = () => {
    setShowCancelModal(!showCancelModal)
    sendUiEvent(uiData.btnCancelDestinationsDesktop)
    if (editState.editMode) {
      sendUiEvent(uiData.btnYesCancelEdit)
      history.push(`${base}`)
      editActions.resetState()
    } else {
      sendUiEvent(uiData.SettingsShortcutDesktop.btnYesCancelCreate)
      history.push(`${base}/add`)
    }
    destinationsActions.resetState()
    settingsActions.resetState()
  }

  const onColorCardPress = (index: number) => {
    settingsActions.updateColor({
      ...settingsState.presentation,
      color: colors[index]
    })
  }

  const createSmartTaskObject = () => {
    const smartTaskConfigObj: SmartTaskConfig = {}

    const removeProp = 'set'

    if (toggleEmailState) {
      const { [removeProp]: set, ...emailConfig } =
        destinationsState.currentState.email!
      smartTaskConfigObj.email = emailConfig
    }

    if (togglePrinterState) {
      const { [removeProp]: set, ...printConfig } =
        destinationsState.currentState.print!
      smartTaskConfigObj.prints = [printConfig]
    }

    if (toggleSaveState) {
      smartTaskConfigObj.repositories =
        destinationsState.currentState.repositories!.selected.map(
          (providerItem) => {
            return {
              connectorId: providerItem.connectorId,
              type: providerItem.type,
              connectorName: providerItem.connectorName,
              folderList: settingsState.foldersList
                .find((item) => item.connectorId === providerItem.connectorId)
                ?.folders?.map((folderItem) => ({
                  folderId: folderItem.id
                }))
                .filter(
                  (folderItem) =>
                    folderItem.folderId !== fallBackFolder.current.id
                )
            }
          }
        )
    }

    if (ocrFileTypes.includes(settingsState.fileType) && isEmailOrSaveSet) {
      smartTaskConfigObj.ocr = {
        ocrLanguage: settingsState.ocrLanguage,
        ocrOutputFileType:
          settingsState.fileType === 'searchablepdf'
            ? 'pdf'
            : settingsState.fileType,
        smartName: settingsState.smartFileName ? 'true' : 'false'
      }
    }

    const smartTask: SmartTask = {
      fileType: ocrFileTypes.includes(settingsState.fileType)
        ? undefined
        : settingsState.fileType === 'standardpdf'
          ? 'pdf'
          : settingsState.fileType,
      jobName: settingsState.jobName.trim(),
      presentation: settingsState.presentation,
      quickrun: settingsState.quickRun,
      smartTaskConfig: smartTaskConfigObj
    }

    return smartTask
  }

  const sendDataPreset = () => {
    if (destinationsState.currentState.repositories.set) {
      destinationsState.currentState.repositories.selected.forEach((item) =>
        sendUiEvent({
          ...uiData.checkboxAddSave,
          controlDetail: resolveProviderDetail(item.type)
        })
      )
    }

    sendUiEvent({
      ...uiData.copiesButton,
      controlDetail: `${destinationsState.currentState.print.numberOfCopies}CopiesButton`
    })

    sendUiEvent({
      ...uiData.colorsButton,
      controlDetail: `${destinationsState.currentState.print.color}Button`
    })

    sendUiEvent({
      ...uiData.twoSidedButton,
      controlDetail: resolveTwoSidedDetail(
        destinationsState.currentState.print.printDuplex
      )
    })

    sendUiEvent({
      ...uiData.SettingsShortcutDesktop.toggleAddEmail,
      controlDetail: toggleEmailState ? 'Enabled' : 'Disabled'
    })

    sendUiEvent({
      ...uiData.SettingsShortcutDesktop.toggleAddPrint,
      controlDetail: togglePrinterState ? 'Enabled' : 'Disabled'
    })

    sendUiEvent({
      ...uiData.SettingsShortcutDesktop.toggleAddSave,
      controlDetail: toggleSaveState ? 'Enabled' : 'Disabled'
    })

    sendUiEvent({
      ...uiData.toggleQuickRunDesktop,
      controlDetail: settingsState.quickRun ? 'Enabled' : 'Disabled'
    })

    sendUiEvent({
      ...uiData.btnSmartFileNameDesktop,
      controlDetail: settingsState.smartFileName ? 'Enabled' : 'Disabled'
    })

    sendUiEvent({
      ...uiData.btnFileTypeDesktop,
      controlDetail: resolveFileTypeDetail(settingsState.fileType)
    })
  }

  const handleUpdateShortcut = async (validateDuplicateName: boolean) => {
    const shortcut: ShortcutItem = {
      smartTask: createSmartTaskObject()
    }

    try {
      const updatedShortcut = await updateShortcut(
        shortcut,
        editState.id,
        validateDuplicateName
      )
      if (updatedShortcut) {
        destinationsActions.resetState()
        settingsActions.resetState()
        editActions.resetState()
        sendDataPreset()
        onSaveShortcut(updatedShortcut, true)
      }
    } catch (e: any) {
      if (e.response.status === 409) {
        setShowDuplicateNameModal(true)
        return
      }
      setIsCreatingShortcut(false)
      addToast?.({
        type: 'warning',
        ...toastMessages('createShortcutError')
      })
    }
  }

  const handleAddShortcut = async (validateDuplicateName: boolean) => {
    const shortcut: ShortcutItem = {
      smartTask: createSmartTaskObject()
    }

    try {
      const createdShortcut = await createShortcut(
        shortcut,
        validateDuplicateName
      )
      if (createdShortcut) {
        sendDataPreset()

        sessionStorage.getItem('emptyShortcutsList') === 'true'
          ? sendUiEvent(uiData.firstShortcutSaved)
          : sendUiEvent(uiData.shortcutSaved)

        destinationsActions.resetState()
        settingsActions.resetState()
        onSaveShortcut(createdShortcut)
      }
    } catch (e: any) {
      if (
        e.response.status === 409 &&
        e.response.data.code === '409-DUPLICATE'
      ) {
        setShowDuplicateNameModal(true)
        return
      }
      setIsCreatingShortcut(false)
      addToast?.({
        type: 'warning',
        ...toastMessages('createShortcutError')
      })
    }
  }

  const onClickSaveShortcut = () => {
    setIsCreatingShortcut(true)
    editState.editMode ? handleUpdateShortcut(true) : handleAddShortcut(true)
  }

  const onConfirmSaveDuplicate = (event: SyntheticEvent) => {
    if (!(event instanceof KeyboardEvent)) {
      setShowDuplicateNameModal(false)
      editState.editMode
        ? handleUpdateShortcut(false)
        : handleAddShortcut(false)
    }
  }

  const onClickRenameButton = () => {
    setShowDuplicateNameModal(false)
    setIsCreatingShortcut(false)
    setDuplicateNameDetected(true)
  }

  const fetchData = useCallback(async () => {
    try {
      setLoadingShortcut(true)

      const result = await getCloudStoragesList()
      const repositories: Connector[] = result.repositories
      const seen = new Set()
      const filteredRepositories = repositories
        .filter((repository) => {
          const duplicate = seen.has(repository.type)
          seen.add(repository.type)
          const isOneDriveBusiness = repository.type === 'onedrivebusiness'
          const isSharepoint = repository.type === 'sharepoint'
          return !duplicate && !isOneDriveBusiness && !isSharepoint
        })
        .map((repository) => {
          return {
            ...Object.assign({}, repository),
            isAuthorized: false,
            connectorName: ''
          }
        })

      const authorizedList = repositories.filter((c) => {
        return c.isAuthorized
      })
      if (authorizedList.length) setAuthorizedProvidersList(authorizedList)

      setProvidersList(filteredRepositories)
      setUpdatingAccountsLoader(false)
    } catch (e) {
      setFetchProvidersListFailed(true)
      setUpdatingAccountsLoader(false)
      addToast?.({
        type: 'negative',
        ...toastMessages('fetchCloudStoragesListError'),
        timeout: 300,
        action: (
          <a
            href={window.location.href}
            onClick={() => window.location.reload()}
          >
            {i18n.t('pages.ListShortcuts.updatePageMessage')}
          </a>
        )
      })
    } finally {
      setLoadingShortcut(false)
    }
  }, [addToast])

  const selectCheckbox = useCallback(
    (providerItem: Connector) => {
      if (!checkboxSelected) {
        const updatedList = [
          ...destinationsState.currentState.repositories!.selected
        ]
        updatedList.push(providerItem)
        destinationsActions.updateRepository({
          ...destinationsState.currentState.repositories,
          selected: updatedList
        })
      }
    },
    [
      checkboxSelected,
      destinationsActions,
      destinationsState.currentState.repositories
    ]
  )

  const updateCheckedRepositoriesList = useCallback(
    (providerItem: Connector) => {
      const updatedList = [
        ...destinationsState.currentState.repositories!.selected
      ]
      if (!updatedList.includes(providerItem)) {
        updatedList.push(providerItem)
        destinationsActions.updateRepository({
          ...destinationsState.currentState.repositories,
          selected: updatedList
        })
      }
    },
    [destinationsState.currentState.repositories, destinationsActions]
  )

  const onSignInPress = useCallback(
    async (providerItem: Connector) => {
      try {
        setLoadingSignInButton(true)
        const scheme = props.connectorRedirectUri.split('://')[0]
        const infos: ConnectorLoginSpecs = {
          redirectUri: props.connectorRedirectUri,
          storage: providerItem.type,
          connectorName: newConnectorName
        }
        const response = await generateCloudStorageLoginURL(infos)

        if (response.status === 201) {
          const signinURL = response.data.signinURL
          const responseOpenAuthBrowser = await openAuthBrowser(
            signinURL,
            scheme
          )
          setLoadingSignInButton(false)
          const error = getAuthBrowserResponseError(responseOpenAuthBrowser)

          if (!error) {
            providerItem.connectorName = infos.connectorName
            setRecentLoggedInConnector(providerItem)
            setUpdatingAccountsLoader(true)
            fetchData()
            sendUiEvent({
              ...uiData.btnSignUpAccountSaveDestinationDesktop,
              controlDetail: resolveAccountSaveDestination(providerItem.type)
            })
          }

          if (error && error !== AuthBrowserErrorType.browserClosedByUser) {
            addToast?.({
              type: 'warning',
              ...toastMessages('cloudStorageLoginError')
            })
            return
          }
        }
        return
      } catch (e) {
        console.error(e)
        setLoadingSignInButton(false)
        addToast?.({
          type: 'warning',
          ...toastMessages('cloudStorageLoginError')
        })
      }
    },
    [addToast, fetchData, newConnectorName, props.connectorRedirectUri]
  )

  const onCheckBoxPress = (providerItem: Connector) => {
    const filteredList =
      destinationsState.currentState.repositories.selected.filter(
        (repository) => {
          return providerItem.connectorId === repository.connectorId
        }
      )
    if (filteredList.length) {
      const updatedList =
        destinationsState.currentState.repositories.selected.filter(
          (repository) => {
            return repository.connectorId !== filteredList[0].connectorId
          }
        )
      destinationsActions.updateRepository({
        ...destinationsState.currentState.repositories,
        selected: updatedList
      })
    } else {
      updateCheckedRepositoriesList(providerItem)
    }
  }

  const onShortcutNameChange = (value: string) => {
    if (duplicateNameDetected) {
      setDuplicateNameDetected(false)
    }
    if (!value.length) {
      setNameErrorMessage(i18n.t('pages.ShortcutSettings.emptyNameMessage'))
    } else if (!isValidShortcutTitle(value) && value.length) {
      setNameErrorMessage(i18n.t('pages.ShortcutSettings.invalidNameMessage'))
    } else {
      setNameErrorMessage('')
    }

    settingsActions.updateJobName(value)
  }

  const isChecked = (providerItem: Connector) => {
    return destinationsState.currentState.repositories.selected.some(
      (repository) => providerItem.connectorId === repository.connectorId
    )
  }

  const toggleStatusModal = () => {
    setShowCancelModal(!showCancelModal)
    sendUiEvent(uiData.screenCancelEdit)
  }

  const validateEmailEntries = () => {
    const { tos } = destinationsState.currentState.email
    const invalidEntries = tos.filter((value) => value && !isValidEmail(value))
    let messageError = ''

    if (invalidEntries.length) {
      messageError = i18n.t('pages.AddEmail.invalidEmailMessage', {
        emails: invalidEntries.join(', ')
      })
    } else if (tos.length > 20) {
      messageError = i18n.t('pages.AddEmail.invalidQuantityEmailMessage')
    }

    setEmailErrorMessage(messageError)
  }

  const getFormattedEmailValue = (value: string) => {
    return value
      .split(',')
      .map((string) => string.trim())
      .join(', ')
  }

  const onChangeEmail = (value: string) => {
    setEmailValue(value)

    if (value.endsWith(',')) {
      validateEmailEntries()
    }

    if (/,\S/.test(value)) {
      setEmailValue(getFormattedEmailValue(value))
    }

    if (!value) {
      setEmailErrorMessage('')
    }

    destinationsActions.updateEmail({
      ...destinationsState.currentState.email,
      tos: value
        .trim()
        .split(/\s*,\s*/)
        .filter((value) => value)
    })
  }

  const onBlurEmail = () => {
    validateEmailEntries()
  }

  const onChangeSubject = (value: string) => {
    destinationsActions.updateEmail({
      ...destinationsState.currentState.email,
      subject: value
    })
  }

  const onChangeBody = (value: string) => {
    destinationsActions.updateEmail({
      ...destinationsState.currentState.email,
      message: value
    })
  }

  const onCopiesSettingChange = (value: number) => {
    destinationsActions.updatePrint({
      ...destinationsState.currentState.print,
      numberOfCopies: value
    })
    setTogglePrinterState(true)
  }

  const onColorSettingChange = (value: 1 | 0) => {
    destinationsActions.updatePrint({
      ...destinationsState.currentState.print,
      color: value === 1 ? 'true' : 'false'
    })
    setTogglePrinterState(true)
  }

  const onTwoSidedSettingChange = (
    value: 'one-sided' | 'two-sided-short-edge' | 'two-sided-long-edge'
  ) => {
    destinationsActions.updatePrint({
      ...destinationsState.currentState.print!,
      printDuplex: value
    })
    setTogglePrinterState(true)
  }

  const onQuickRunClick = () => {
    settingsActions.updateQuickRun(!settingsState.quickRun)
  }

  const onSmartFileNameClick = () => {
    settingsActions.updateSmartFileName(!settingsState.smartFileName)
  }

  const onOcrLanguageChange = (value: string) => {
    settingsActions.updateOcrLanguage(value)
  }

  const onCloseDeleteModal = (id: string | undefined) => {
    setShowDeleteModal(!showDeleteModal)

    if (typeof id === 'string' || !id) {
      history.push(`${base}/list`)
      destinationsActions.resetState()
      settingsActions.resetState()
      editActions.resetState()
    }
  }

  const onClickDeleteButton = () => {
    setShowDeleteModal(!showDeleteModal)
    sendUiEvent(uiData.modalDeleteShortcut)
  }

  const handleSaveButtonDisable = () => {
    const invalidName = !(
      settingsState.jobName.length &&
      settingsState.jobName.trim() &&
      !nameErrorMessage.length
    )
    const invalidEmailConfig = !(
      destinationsState.currentState.email.tos.length &&
      !emailErrorMessage &&
      destinationsState.currentState.email.subject.length &&
      destinationsState.currentState.email.subject.trim()
    )
    const invalidSaveConfig =
      !destinationsState.currentState.repositories.selected.length
    const togglesOff = !(
      togglePrinterState ||
      toggleEmailState ||
      toggleSaveState
    )
    const disabled =
      invalidName ||
      (invalidEmailConfig && toggleEmailState) ||
      (invalidSaveConfig && toggleSaveState) ||
      togglesOff ||
      loadingShortcut
    return disabled
  }

  useEffect(() => {
    if (recentlyLoggedInConnector) {
      const connector = authorizedProvidersList.find((connector) => {
        return (
          connector.connectorName === recentlyLoggedInConnector.connectorName
        )
      })
      if (connector) {
        updateCheckedRepositoriesList(connector)
        setRecentLoggedInConnector(undefined)
      } else {
        fetchData()
      }
    }
  }, [
    authorizedProvidersList,
    fetchData,
    recentlyLoggedInConnector,
    updateCheckedRepositoriesList
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    setNewConnectorName(resolveConnectorName(authorizedProvidersList))
  }, [authorizedProvidersList])

  //This useEffect will guarantee if the currentConnectorId has not changed then the tree will not be cleaned
  useEffect(() => {
    cleanFolderTree()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState.currentConnectorId])

  useEffect(() => {
    async function updateData() {
      setUpdatingAccountsLoader(true)
      await fetchData()
      setUpdatingAccountsLoader(false)
    }

    if (editConnectorNameSuccess) {
      updateData()
      setEditConnectorNameSuccess(false)
    }
  }, [editConnectorNameSuccess, fetchData])

  useEffect(() => {
    if (!settingsState.presentation.color) {
      settingsActions.updateColor({
        ...settingsState.presentation,
        color: randomColor()
      })
    }
  }, [settingsActions, settingsState.presentation])

  useEffect(() => {
    setToggleEmailState(destinationsState.currentState.email?.set)
  }, [destinationsState.currentState.email?.set])

  useEffect(() => {
    setToggleSaveState(destinationsState.currentState.repositories?.set)
  }, [destinationsState.currentState.repositories?.set])

  useEffect(() => {
    if (
      (editState.editMode || location.state?.template) &&
      destinationsState.currentState.print.set
    ) {
      setTogglePrinterState(destinationsState.currentState.print.set)
    }
  }, [
    destinationsState.currentState.print,
    editState,
    location.state?.template
  ])

  useEffect(() => {
    if (
      !suggestionLoaded &&
      (!destinationsState.currentState.email.set || location.state?.template) &&
      !destinationsState.currentState.email.subject &&
      !destinationsState.currentState.email.message
    ) {
      destinationsActions.updateEmail({
        tos: [],
        subject: i18n.t('pages.AddEmail.subjectSuggestion', {
          userFirstName: userContextState.givenName ?? ''
        }),
        // TODO: Replace once translation batch is available i18n.t('pages.AddEmail.bodySuggestionHPX', {userFirstName: userContextState.givenName ?? '',userEmail: userContextState.emailAddress ?? ''}),
        message: `The attached files have been sent to you from ${userContextState.givenName ?? ''} (${userContextState.emailAddress ?? ''}). Use the HP app to edit, print, and share them!`,
        set: destinationsState.currentState.email.set
      })
      setSuggestionLoaded(true)
    }
  }, [
    destinationsActions,
    destinationsState.currentState.email.message,
    destinationsState.currentState.email.set,
    destinationsState.currentState.email.subject,
    location.state?.template,
    userContextState.emailAddress,
    userContextState.givenName,
    suggestionLoaded
  ])

  useEffect(() => {
    if (
      location.state?.template &&
      (authorizedProvidersList.length > 0 || providersList.length > 0) &&
      !templateTriggered
    ) {
      const action =
        location.state?.template.actions[location.state?.template.index]
      if (action.triggerSignIn) {
        if (
          authorizedProvidersList.length === 0 ||
          !authorizedProvidersList.some(
            (repository) => action.signInTargetType?.type === repository.type
          )
        ) {
          onSignInPress(action.signInTargetType!)
        } else {
          const matchingConnectors = authorizedProvidersList.filter(
            (item) => item.type === action.signInTargetType?.type
          )
          selectCheckbox(matchingConnectors[0])
          setCheckboxSelected(true)
        }
      }
      setTemplateTriggered(true)
    }
  }, [
    authorizedProvidersList,
    location.state?.template,
    onSignInPress,
    selectCheckbox,
    templateTriggered,
    providersList
  ])

  useEffect(() => {
    setShowFileType(toggleEmailState || toggleSaveState)
  }, [toggleEmailState, toggleSaveState, togglePrinterState])

  const handleOcrDefaultLocale = useCallback(() => {
    const locale = determineOcrLanguage()
    return locale
  }, [])

  useEffect(() => {
    const fetchOcrLanguage = async () => {
      const ocrLanguage = await getPrinterDefaultOcrLanguage(
        props.selectedPrinterUuid as string
      )
      settingsActions.updateOcrLanguage(ocrLanguage ?? handleOcrDefaultLocale())
    }

    if (
      !editState.editMode ||
      (editState.editMode && !settingsState.ocrLanguage)
    ) {
      fetchOcrLanguage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedPrinterUuid])

  useEffect(() => {
    if (
      contextualInfo.showModalFolderListing &&
      settingsState.currentConnectorId
    ) {
      onFolderListingOpen(settingsState.currentConnectorId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextualInfo.showModalFolderListing,
    onFolderListingOpen,
    settingsState.currentConnectorId
  ])

  //This useEffect will guarantee  that whenever the user remove all his folders that fallback folder will be added
  useEffect(() => {
    if (settingsState.currentConnectorId) {
      const currentConnectorIndex = settingsState.foldersList.findIndex(
        (connectorItem) =>
          connectorItem.connectorId === settingsState.currentConnectorId
      )

      if (
        currentConnectorIndex >= 0 &&
        settingsState.foldersList[currentConnectorIndex].folders.length === 0
      ) {
        settingsState.foldersList[currentConnectorIndex].folders = [
          fallBackFolder.current
        ]
        settingsActions.updateFoldersList([...settingsState.foldersList])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState.foldersList, settingsState.currentConnectorId])

  // This useEffect will close the modal and show a toast whenever an error happen when fetching folders.
  useEffect(() => {
    if (errorOnFetchFolder) {
      setContextualInfo((prev) => ({
        ...prev,
        showModalFolderListing: false
      }))
      addToast?.({
        type: 'warning',
        ...toastMessages('fetchFoldersError')
      })
      setErrorOnFetchFolder(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorOnFetchFolder])

  useEffect(() => {
    settingsActions.updateFolderTree(folderTree)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderTree])

  useEffect(() => {
    if (!editState.editMode) {
      settingsActions.updateFoldersList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageTemplate
      id="destinations-desktop-mode"
      desktopMode={props.desktopMode}
      content={
        <>
          {providersList.length > 0 ||
          authorizedProvidersList.length > 0 ||
          fetchProvidersListFailed ? (
            <GenericCard>
              <GenericCard.Body>
                <div className="subtitle-large-medium">
                  {editState.editMode
                    ? i18n.t('pages.Destinations.editShortcut')
                    : i18n.t('pages.AddShortcut.header')}
                </div>
                <div
                  className="body-regular"
                  style={{ color: colorsTokens.inkSecondary, marginTop: '8px' }}
                >
                  {i18n.t('pages.Destinations.addCard')}
                </div>
                <ShortcutNameContainer id="shortcut-name-container">
                  <ShortcutName
                    value={settingsState.jobName}
                    onChange={onShortcutNameChange}
                    fieldLabel={i18n.t('pages.ShortcutSettings.shortcutName')}
                    duplicateNameWarningText={
                      duplicateNameDetected
                        ? i18n.t('pages.Destinations.duplicateName')
                        : ''
                    }
                    errorMessage={nameErrorMessage}
                    desktopMode={props.desktopMode}
                  />
                </ShortcutNameContainer>
                <SectionListContainer
                  paddingStart={false}
                  paddingEnd={false}
                >
                  <SectionListContainer.Section>
                    <SectionListContainer.SectionHeader
                      title={i18n.t('pages.Destinations.destinations')}
                    />
                    <SectionListContainer.SectionBody>
                      <DestinationsToggleList
                        printTitle={i18n.t('pages.Destinations.printTitle')}
                        emailTitle={i18n.t('pages.Destinations.emailTitle')}
                        saveTitle={i18n.t('pages.Destinations.saveTitle')}
                        emailContent={
                          <EmailForm
                            toFieldLabel={i18n.t('pages.AddEmail.to')}
                            subjectFieldLabel={i18n.t('pages.AddEmail.subject')}
                            bodyFieldLabel={i18n.t('pages.AddEmail.body')}
                            onChangeEmailCallback={onChangeEmail}
                            onBlurEmailCallback={onBlurEmail}
                            onChangeSubjectCallback={onChangeSubject}
                            onChangeBodyCallback={onChangeBody}
                            emailValue={emailValue}
                            subjectValue={
                              destinationsState.currentState.email.subject
                            }
                            bodyValue={
                              destinationsState.currentState.email.message
                            }
                            emailErrorMessage={emailErrorMessage}
                          />
                        }
                        printContent={
                          <PrintOptions
                            desktopMode={props.desktopMode}
                            copiesFieldOptions={CopiesConfigOptionsList}
                            copiesFieldLabel={i18n.t('pages.AddPrint.copies')}
                            numberOfCopies={[
                              destinationsState.currentState.print
                                .numberOfCopies
                            ]}
                            onChangeCopies={(value: any) =>
                              onCopiesSettingChange(value)
                            }
                            colorFieldOptions={[
                              {
                                value: 1,
                                label: i18n.t('pages.AddPrint.color')
                              },
                              {
                                value: 0,
                                label: i18n.t('pages.AddPrint.grayscale')
                              }
                            ]}
                            colorFieldLabel={i18n.t('pages.AddPrint.color')}
                            colorFieldValue={[
                              Number(
                                destinationsState.currentState.print.color ===
                                  'true'
                              )
                            ]}
                            onChangeColor={(value: any) =>
                              onColorSettingChange(value)
                            }
                            twoSidedFieldOptions={[
                              {
                                value: 'one-sided',
                                label: i18n.t('pages.AddPrint.off')
                              },
                              {
                                value: 'two-sided-short-edge',
                                label: i18n.t('pages.AddPrint.sedge')
                              },
                              {
                                value: 'two-sided-long-edge',
                                label: i18n.t('pages.AddPrint.ledge')
                              }
                            ]}
                            twoSidedFieldLabel={i18n.t(
                              'pages.AddPrint.two-sided'
                            )}
                            twoSidedFieldValue={[
                              destinationsState.currentState.print.printDuplex
                            ]}
                            onChangeTwoSided={(value: any) =>
                              onTwoSidedSettingChange(value)
                            }
                          />
                        }
                        saveContent={
                          <SaveOptions
                            authorizedProvidersList={authorizedProvidersList}
                            providersList={providersList}
                            desktopMode={props.desktopMode}
                            isChecked={isChecked}
                            onCheckBoxPress={onCheckBoxPress}
                            onSignInPress={onSignInPress}
                            onContextualMenuOptionClick={(
                              providerItem,
                              option
                            ) =>
                              handleContextualMenuOptionClick(
                                providerItem,
                                option
                              )
                            }
                            loadingSignInButton={loadingSignInButton}
                            updatingAccountsLoader={updatingAccountsLoader}
                            contextualMenuOptions={getAddSaveOptions()}
                            onDestinationEditClick={onClickEditSelectedFolder}
                            onDestinationDeleteClick={
                              onClickDeleteSelectedFolder
                            }
                            destinationFolder={settingsState.foldersList}
                            currentConnectorId={
                              settingsState.currentConnectorId
                            }
                          />
                        }
                        printSubtitle={
                          <p>{i18n.t('pages.Destinations.printSubtitle')}</p>
                        }
                        emailSubtitle={
                          <p>{i18n.t('pages.Destinations.emailSubtitle')}</p>
                        }
                        saveSubtitle={
                          <p>{i18n.t('pages.Destinations.saveSubtitle')}</p>
                        }
                        isPrintDestinationSet={
                          destinationsState.currentState.print.set
                        }
                        isEmailDestinationSet={
                          destinationsState.currentState.email.set
                        }
                        isSaveDestinationSet={
                          destinationsState.currentState.repositories.set
                        }
                        enablePrinterToggle={togglePrinterState}
                        enableEmailToggle={toggleEmailState}
                        enableSaveToggle={toggleSaveState}
                        handleTogglePrinter={handlePrinterToggle}
                        handleToggleEmail={handleEmailToggle}
                        handleToggleSave={handleSaveToggle}
                      />
                    </SectionListContainer.SectionBody>
                  </SectionListContainer.Section>
                </SectionListContainer>

                <SectionListContainer
                  paddingStart={false}
                  paddingEnd={false}
                >
                  <SectionListContainer.Section>
                    <SectionListContainer.SectionHeader
                      title={i18n.t('pages.ShortcutSettings.header')}
                    />
                    <SectionListContainer.SectionBody>
                      <SettingsContainer>
                        <ShortcutSettingsForm
                          nameFieldValue={settingsState.jobName}
                          nameFieldLabel={i18n.t(
                            'pages.ShortcutSettings.shortcutName'
                          )}
                          onChangeName={onShortcutNameChange}
                          quickRunDescription={i18n.t(
                            'pages.ShortcutSettings.quickRunDescription'
                          )}
                          quickRunFieldLabel={i18n.t(
                            'pages.ShortcutSettings.quickRun'
                          )}
                          isQuickRunOn={settingsState.quickRun}
                          onQuickRunClick={onQuickRunClick}
                          /* TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSettings.tileColorHPX') */
                          tileColorFieldLabel={'Shortcut color'}
                          selectedColorCardIndex={colors.indexOf(
                            settingsState.presentation.color!
                          )}
                          onColorCardPress={onColorCardPress}
                          desktopMode={props.desktopMode}
                          nameErrorMessage={nameErrorMessage}
                          showFileType={showFileType}
                          onClickFileType={onClickFileType}
                          isEntitled={userContextState.isEntitled}
                          fileType={settingsState.fileType}
                          onSmartFileNameClick={onSmartFileNameClick}
                          isSmartNameOn={settingsState.smartFileName}
                          showOcrConfigs={
                            ocrFileTypes.includes(settingsState.fileType) &&
                            isEmailOrSaveSet
                          }
                          ocrLanguage={settingsState.ocrLanguage}
                          ocrSelectLabel={i18n.t(
                            'pages.ShortcutSettings.language'
                          )}
                          onChangeOcrLanguage={(value: string) =>
                            onOcrLanguageChange(value)
                          }
                        />
                      </SettingsContainer>
                    </SectionListContainer.SectionBody>
                  </SectionListContainer.Section>
                </SectionListContainer>
                {editState.editMode ? (
                  <CustomFooter
                    desktopMode={props.desktopMode}
                    leftButtons={[
                      {
                        id: 'delete-shortcut-btn',
                        text: i18n.t(
                          'pages.ShortcutSettings.deleteShortcutButton'
                        ),
                        disabled: isCreatingShortcut || loadingShortcut,
                        onClick: onClickDeleteButton,
                        appearance: 'negative'
                      }
                    ]}
                    buttons={[
                      {
                        id: 'cancel-edition-shortcut-btn',
                        text: i18n.t('common.contextAction.cancel'),
                        disabled: isCreatingShortcut || loadingShortcut,
                        onClick: toggleStatusModal,
                        appearance: 'secondary'
                      },
                      {
                        id: 'save-edition-shortcut-btn',
                        text: i18n.t('common.contextAction.save'),
                        disabled:
                          isCreatingShortcut || handleSaveButtonDisable(),
                        loading: isCreatingShortcut,
                        onClick: onClickSaveShortcut,
                        appearance: 'primary'
                      }
                    ]}
                  />
                ) : (
                  <CustomFooter
                    desktopMode={props.desktopMode}
                    buttons={[
                      {
                        id: 'cancel-creation-shortcut-btn',
                        text: i18n.t('common.contextAction.cancel'),
                        disabled: loadingShortcut || isCreatingShortcut,
                        onClick: toggleStatusModal,
                        appearance: 'secondary'
                      },
                      {
                        id: 'save-creation-shortcut-btn',
                        text: i18n.t('common.contextAction.save'),
                        disabled:
                          isCreatingShortcut || handleSaveButtonDisable(),
                        loading: isCreatingShortcut,
                        onClick: onClickSaveShortcut,
                        appearance: 'primary'
                      }
                    ]}
                  />
                )}
              </GenericCard.Body>
            </GenericCard>
          ) : (
            <ContentLoader>
              <Loader />
            </ContentLoader>
          )}
          <ModalDelete
            show={showDeleteModal}
            onClose={onCloseDeleteModal}
          />
          <ModalSavedShortcut
            show={showSavedShortcutModal}
            shortcut={savedShortcut}
            base={base}
            desktopMode={props.desktopMode}
            editMode={isUpdate}
          />
          <Modal
            aria-label="confirmation-modal"
            onClose={toggleStatusModal}
            closeOnBlur={false}
            isOpen={showCancelModal}
            /* TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSettings.modalTitleHPX') */
            title={'Cancel this Shortcut?'}
            align="center"
            buttonGroup={{
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalCancelEditsButtonHPX')
              textPrimaryButton: 'Yes, cancel',
              primaryButtonType: 'negative',
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalContinueEditsButtonHPX')
              textSecondaryButton: 'Go back',
              onClickPrimaryButton: () => {
                onClickCancel()
              },
              onClickSecondaryButton: () => {
                toggleStatusModal()
              },
              size: 'medium',
              orientation: 'vertical'
            }}
          >
            {/* TODO: Replace when batch translation is done:
        i18n.t('pages.Destinations.modalDescriptionHPX') */}
            <p>{'Any edits you made will be lost.'}</p>
          </Modal>
          <ModalEditAccountName
            success={() => setEditConnectorNameSuccess(true)}
            connector={contextualInfo.connector}
            repositories={contextualInfo.repositories}
            show={contextualInfo.showModalEditName}
            onCancel={onClickEditAcccountNameCancel}
            desktopMode={props.desktopMode}
          />
          <ModalRemoveAccess
            show={contextualInfo.showModalRemoveAccess}
            onConfirm={onClickRemoveAccessButton}
            providerType={resolveRepositoryType(currentRepository?.type || '')}
          />
          <ModalDuplicateName
            show={showDuplicateNameModal}
            onClose={onConfirmSaveDuplicate}
            onConfirm={() => onClickRenameButton()}
            shortcutName={settingsState.jobName}
            desktopMode={props.desktopMode}
          />
          <FolderListingModal
            isVisible={contextualInfo.showModalFolderListing}
            isLoading={isLoadingFolders}
            folderTree={settingsState.folderTree}
            onNodeExpand={onNodeExpand}
            onNodeSelect={onNodeSelect}
            onConfirm={onClickSaveFolderModal}
            onClose={onClickCancelFolderModal}
            currentConnector={settingsState.currentConnectorId!}
            editSelectedFolder={settingsState.editSelectedFolder}
          />
        </>
      }
    ></PageTemplate>
  )
}
