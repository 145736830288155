import { ButtonGroup, GenericCard, Modal } from '@clientos/ui-toolkit'
import { useToast } from '@veneer/core'
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MobileScroll from 'src/components/MobileScroll'
import ModalSavedShortcut from 'src/components/ModalSavedShortcut'
import { useToggleContext } from 'src/contexts/toggle/ToggleContext'
import { useUserContext } from 'src/contexts/users/UserContext'
import PageTemplate from 'src/pages/PageTemplate'
import { i18n } from '../../assets/locale'
import { createShortcut, updateShortcut } from '../../clients/shortcuts'
import Header from '../../components/Header'
import ModalDuplicateName from '../../components/ModalDuplicateName'
import ShortcutSettingsForm from '../../components/ShortcutSettingsForm'
import { useAnimationContext } from '../../contexts/animations/AnimationContext'
import { useDestinationsContext } from '../../contexts/destinations/DestinationsContext'
import { useEditContext } from '../../contexts/edit/EditContext'
import { useSettingsContext } from '../../contexts/settings/SettingsContext'
import { colors, randomColor } from '../../data/enum/colors'
import { ocrFileTypes } from '../../data/enum/ocrFileTypes'
import { PageProps } from '../../data/schemas/app'
import { LocationState } from '../../data/schemas/location'
import {
  ShortcutItem,
  SmartTask,
  SmartTaskConfig
} from '../../data/schemas/shortcut'
import useNativeBackButton from '../../hooks/useNativeBackButton'
import {
  determineOcrLanguage,
  getPrinterDefaultOcrLanguage,
  resolveFileTypeDetail,
  resolveProviderDetail,
  toastMessages
} from '../../modules/common/helpers'
import { isValidShortcutTitle } from '../../modules/destinations/helpers'
import { sendUiEvent, uiData } from '../../plugins/jweb/DataCollection'
import { MainContainer } from './styles'

export const ShortcutSettings = ({ base, props }: PageProps) => {
  const history = useHistory<LocationState>()
  const location = useLocation<LocationState>()

  const deviceModel = props.deviceModel

  const { addToast } = useToast()
  const { setAnimationDirection } = useAnimationContext()
  const { settingsState, settingsActions } = useSettingsContext()
  const { destinationsState, destinationsActions } = useDestinationsContext()
  const { editState, editActions } = useEditContext()
  const { toggleState, toggleActions } = useToggleContext()
  const { userContextState } = useUserContext()

  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showDuplicateNameModal, setShowDuplicateNameModal] = useState(false)
  const [isCreatingShortcut, setIsCreatingShortcut] = useState(false)
  const [showFooter, setShowFooter] = useState(true)
  const [showSavedShortcutModal, setShowSavedShortcutModal] = useState(false)
  const [savedShortcut, setSavedShortcut] = useState<ShortcutItem>()
  const [height] = useState(document.documentElement.clientHeight)
  const [customHeight, setCustomHeight] = useState(
    document.documentElement.clientHeight
  )

  const showFileType = () => {
    return (
      destinationsState.currentState.email!.set ||
      destinationsState.currentState.repositories!.set
    )
  }

  const onBackButtonPress = () => {
    setAnimationDirection('left')
    if (location.state?.template) {
      const index = location.state?.template.index
      history.push(`${base}/${location.state?.template.actions[index].route}`, {
        template: location.state?.template
      })
    } else {
      if (toggleState.save) {
        history.push(`${base}/repositories`)
      } else if (toggleState.email) {
        history.push(`${base}/email`)
      } else if (toggleState.print) {
        history.push(`${base}/print`)
      } else {
        history.push(`${base}/destinations`)
      }
    }
  }

  const onQuickRunClick = () => {
    settingsActions.updateQuickRun(!settingsState.quickRun)
  }

  const onSmartFileNameClick = () => {
    settingsActions.updateSmartFileName(!settingsState.smartFileName)
  }

  const onOcrLanguageChange = (value: string) => {
    settingsActions.updateOcrLanguage(value)
  }

  const onColorCardPress = (index: number) => {
    settingsActions.updateColor({
      ...settingsState.presentation,
      color: colors[index]
    })
  }

  const onShortcutNameChange = (value: string) => {
    if (!value.length) {
      setNameErrorMessage(i18n.t('pages.ShortcutSettings.emptyNameMessage'))
    } else if (!isValidShortcutTitle(value) && value.length) {
      setNameErrorMessage(i18n.t('pages.ShortcutSettings.invalidNameMessage'))
    } else {
      setNameErrorMessage('')
    }

    settingsActions.updateJobName(value)
  }

  const onClickFileTypeButton = (_e: SyntheticEvent, value: string) => {
    settingsActions.updateFileType(value)
  }

  const onClickSaveShortcut = () => {
    setIsCreatingShortcut(true)
    editState.editMode ? handleUpdateShortcut(true) : handleAddShortcut(true)
    toggleActions.resetState()
  }

  const changeModalStatus = () => {
    setShowCancelModal(!showCancelModal)
  }

  const createSmartTaskObject = () => {
    const smartTaskConfigObj: SmartTaskConfig = {}

    const removeProp = 'set'

    if (destinationsState.currentState.email.set) {
      toggleActions.setEmailToggle(true)
      const { [removeProp]: set, ...emailConfig } =
        destinationsState.currentState.email
      smartTaskConfigObj.email = emailConfig
    }

    if (destinationsState.currentState.print.set) {
      toggleActions.setPrintToggle(true)
      const { [removeProp]: set, ...printConfig } =
        destinationsState.currentState.print
      smartTaskConfigObj.prints = [printConfig]
    }

    if (destinationsState.currentState.repositories.set) {
      toggleActions.setSaveToggle(true)
      smartTaskConfigObj.repositories =
        destinationsState.currentState.repositories.selected.map(
          (providerItem) => {
            return {
              connectorId: providerItem.connectorId,
              type: providerItem.type,
              connectorName: providerItem.connectorName,
              folderList: settingsState.foldersList
                .find((item) => item.connectorId === providerItem.connectorId)
                ?.folders?.map((folderItem) => ({
                  folderId: folderItem.id
                }))
                .filter((folderItem) => folderItem.folderId !== 'HP Smart')
            }
          }
        )
    }

    if (
      ocrFileTypes.includes(settingsState.fileType) &&
      (destinationsState.currentState.email.set ||
        destinationsState.currentState.repositories.set)
    ) {
      smartTaskConfigObj.ocr = {
        ocrLanguage: settingsState.ocrLanguage,
        ocrOutputFileType:
          settingsState.fileType === 'searchablepdf'
            ? 'pdf'
            : settingsState.fileType,
        smartName: settingsState.smartFileName ? 'true' : 'false'
      }
    }

    const smartTask: SmartTask = {
      fileType: ocrFileTypes.includes(settingsState.fileType)
        ? undefined
        : settingsState.fileType === 'standardpdf'
          ? 'pdf'
          : settingsState.fileType,
      jobName: settingsState.jobName.trim(),
      presentation: settingsState.presentation,
      quickrun: settingsState.quickRun,
      smartTaskConfig: smartTaskConfigObj
    }

    return smartTask
  }

  const onClickCancelButton = () => {
    editActions.resetState()
    destinationsActions.resetState()
    settingsActions.resetState()
    setAnimationDirection('left')
    history.push(`${base}`)
  }

  const onConfirmSaveDuplicate = () => {
    setShowDuplicateNameModal(false)
    editState.editMode ? handleUpdateShortcut(false) : handleAddShortcut(false)
  }

  const onClickRenameButton = () => {
    setShowDuplicateNameModal(false)
    setIsCreatingShortcut(false)
  }

  const onSaveShortcut = (shortcut: ShortcutItem) => {
    setSavedShortcut(shortcut)
    setShowSavedShortcutModal(true)
  }

  const sendDataAnalytics = () => {
    if (destinationsState.currentState.repositories.set) {
      destinationsState.currentState.repositories.selected.forEach((item) =>
        sendUiEvent({
          ...uiData.checkboxAddSave,
          controlDetail: resolveProviderDetail(item.type)
        })
      )
    }
    sendUiEvent({
      ...uiData.toggleQuickRunMobile,
      controlDetail: settingsState.quickRun ? 'Enabled' : 'Disabled'
    })

    sendUiEvent({
      ...uiData.btnSmartFileNameMobile,
      controlDetail: settingsState.smartFileName ? 'Enabled' : 'Disabled'
    })

    sendUiEvent({
      ...uiData.btnFileTypeMobile,
      controlDetail: resolveFileTypeDetail(settingsState.fileType)
    })
  }

  const handleAddShortcut = async (validateDuplicateName: boolean) => {
    const shortcut: ShortcutItem = {
      smartTask: createSmartTaskObject()
    }

    try {
      const createdShortcut = await createShortcut(
        shortcut,
        validateDuplicateName
      )
      if (createdShortcut) {
        destinationsActions.resetState()
        settingsActions.resetState()

        sendDataAnalytics()
        const isFirstCreation =
          sessionStorage.getItem('emptyShortcutsList') === 'true'
        isFirstCreation
          ? sendUiEvent(uiData.firstShortcutSaved)
          : sendUiEvent(uiData.shortcutSaved)

        onSaveShortcut(createdShortcut)
      }
    } catch (e: any) {
      if (
        e.response.status === 409 &&
        e.response.data.code === '409-DUPLICATE'
      ) {
        setShowDuplicateNameModal(true)
        return
      }
      setIsCreatingShortcut(false)
      addToast?.({
        type: 'warning',
        ...toastMessages('createShortcutError')
      })
    }
  }

  const handleUpdateShortcut = async (validateDuplicateName: boolean) => {
    const shortcut: ShortcutItem = {
      smartTask: createSmartTaskObject()
    }

    try {
      const updatedShortcut = await updateShortcut(
        shortcut,
        editState.id,
        validateDuplicateName
      )

      if (updatedShortcut) {
        destinationsActions.resetState()
        settingsActions.resetState()
        editActions.resetState()

        sendDataAnalytics()
        sendUiEvent(uiData.shortcutEditedSaved)

        onSaveShortcut(updatedShortcut)
      }
    } catch (e: any) {
      if (e.response.status === 409) {
        setShowDuplicateNameModal(true)
        return
      }
      setIsCreatingShortcut(false)
      addToast?.({
        type: 'warning',
        ...toastMessages('createShortcutError')
      })
    }
  }

  const handleOcrDefaultLocale = useCallback(() => {
    const locale = determineOcrLanguage()
    return locale
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchOcrLanguage = async () => {
      const ocrLanguage = await getPrinterDefaultOcrLanguage(
        props.selectedPrinterUuid as string
      )
      settingsActions.updateOcrLanguage(ocrLanguage ?? handleOcrDefaultLocale())
    }

    if (
      !editState.editMode ||
      (editState.editMode && !settingsState.ocrLanguage)
    ) {
      fetchOcrLanguage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedPrinterUuid])

  useEffect(() => {
    const updateSize = () => {
      if (!deviceModel.includes('ipad')) {
        setShowFooter(document.documentElement.clientHeight === height)
        setCustomHeight(document.documentElement.clientHeight)
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [customHeight, deviceModel, height])

  useEffect(() => {
    if (!settingsState.presentation.color) {
      settingsActions.updateColor({
        ...settingsState.presentation,
        color: randomColor()
      })
    }
  }, [settingsActions, settingsState.presentation])

  useEffect(() => {
    if (settingsState.jobName.length && !nameErrorMessage.length) {
      onShortcutNameChange(settingsState.jobName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useNativeBackButton(onBackButtonPress)

  return (
    <PageTemplate
      desktopMode={props.desktopMode}
      header={
        <Header
          backButtonLabel={'Cancel'}
          onPressIconLeft={onBackButtonPress}
        />
      }
      content={
        <MainContainer id="main-container-shortcut-settings">
          <GenericCard id="content-container-shortcut-settings">
            <GenericCard.Header
              id="intro-container-shortcut-settings"
              /* TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSettings.shortcutSettingsHPX') */
              title={'Shortcut setting'}
              borderBottom={false}
            />
            <GenericCard.Body>
              <MobileScroll offset={editState.editMode ? '385px' : '310px'}>
                <ShortcutSettingsForm
                  nameFieldValue={settingsState.jobName}
                  nameFieldLabel={i18n.t('pages.ShortcutSettings.shortcutName')}
                  onChangeName={onShortcutNameChange}
                  quickRunDescription={i18n.t(
                    'pages.ShortcutSettings.quickRunDescription'
                  )}
                  quickRunFieldLabel={i18n.t('pages.ShortcutSettings.quickRun')}
                  isQuickRunOn={settingsState.quickRun}
                  onQuickRunClick={onQuickRunClick}
                  /* TODO: Replace when batch translation is done: i18n.t('pages.ShortcutSettings.tileColorHPX') */
                  tileColorFieldLabel={'Shortcut color'}
                  selectedColorCardIndex={colors.indexOf(
                    settingsState.presentation.color!
                  )}
                  onColorCardPress={onColorCardPress}
                  desktopMode={props.desktopMode}
                  nameErrorMessage={nameErrorMessage}
                  showFileType={showFileType()}
                  onClickFileType={onClickFileTypeButton}
                  isEntitled={userContextState.isEntitled}
                  fileType={settingsState.fileType}
                  onSmartFileNameClick={onSmartFileNameClick}
                  isSmartNameOn={settingsState.smartFileName}
                  showOcrConfigs={ocrFileTypes.includes(settingsState.fileType)}
                  ocrLanguage={settingsState.ocrLanguage}
                  ocrSelectLabel={i18n.t('pages.ShortcutSettings.language')}
                  onChangeOcrLanguage={(value: any) =>
                    onOcrLanguageChange(value)
                  }
                />
              </MobileScroll>
            </GenericCard.Body>
          </GenericCard>
          <ModalDuplicateName
            show={showDuplicateNameModal}
            onClose={onConfirmSaveDuplicate}
            onConfirm={() => onClickRenameButton()}
            shortcutName={settingsState.jobName}
            desktopMode={props.desktopMode}
          />
          <ModalSavedShortcut
            show={showSavedShortcutModal}
            shortcut={savedShortcut}
            base={base}
            desktopMode={props.desktopMode}
          />
          <Modal
            aria-label="confirmation-modal"
            onClose={changeModalStatus}
            closeOnBlur={false}
            isOpen={showCancelModal}
            /* TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalTitleHPX') */
            title={'Cancel this Shortcut?'}
            align="center"
            modalSize="small"
            buttonGroup={{
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalCancelEditsButtonHPX')
              textPrimaryButton: 'Yes, cancel',
              primaryButtonType: 'negative',
              // TODO: Replace when batch translation is done: i18n.t('pages.Destinations.modalContinueEditsButtonHPX')
              textSecondaryButton: 'Go back',
              onClickPrimaryButton: () => {
                onClickCancelButton()
              },
              onClickSecondaryButton: () => {
                changeModalStatus()
              },
              size: 'large',
              orientation: 'vertical'
            }}
          >
            {/* TODO: Replace when batch translation is done:
        i18n.t('pages.Destinations.modalDescriptionHPX') */}
            <p>{'Any edits you made will be lost.'}</p>
          </Modal>
        </MainContainer>
      }
      mobileFooter={
        showFooter &&
        (editState.editMode ? (
          <ButtonGroup
            idSecondaryButton="cancel-button-edit-shortcut-settings"
            textPrimaryButton={i18n.t('pages.ShortcutSettings.button')}
            textSecondaryButton={i18n.t('common.contextAction.cancel')}
            size="large"
            orientation="vertical"
            onClickPrimaryButton={onClickSaveShortcut}
            onClickSecondaryButton={changeModalStatus}
            disabled={
              !(
                settingsState.jobName.length &&
                settingsState.jobName.trim() &&
                !nameErrorMessage.length
              ) || isCreatingShortcut
            }
          />
        ) : (
          <ButtonGroup
            textPrimaryButton={i18n.t('pages.ShortcutSettings.button')}
            size="large"
            orientation="vertical"
            onClickPrimaryButton={onClickSaveShortcut}
            disabled={
              !(
                settingsState.jobName.length &&
                settingsState.jobName.trim() &&
                !nameErrorMessage.length
              ) || isCreatingShortcut
            }
          />
        ))
      }
    />
  )
}
