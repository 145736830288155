import { Scrollbar } from '@clientos/ui-toolkit'
import React from 'react'
import {
  DesktopContent,
  FooterContent,
  MobileContent,
  PageContainer,
  PageContent,
  PageFooter,
  PageHeader
} from './styles'

type PageTemplateProps = {
  header?: React.ReactNode
  content: React.ReactNode
  mobileFooter?: React.ReactNode
  desktopMode: boolean
  id?: string
}

const PageTemplate = (props: PageTemplateProps) => {
  return (
    <PageContainer id={props.id}>
      {props.header && (
        <PageHeader className="shortcuts-header">{props.header}</PageHeader>
      )}
      {props.content && (
        <PageContent
          className="shortcuts-content"
          style={{
            padding: props.desktopMode ? '0 0 0 16px' : '0 16px'
          }}
        >
          {props.desktopMode ? (
            <Scrollbar
              height={
                props.header ? 'calc(100vh - 120px)' : 'calc(100vh - 60px)'
              }
              marginRight={false}
            >
              <DesktopContent>{props.content}</DesktopContent>
            </Scrollbar>
          ) : (
            <MobileContent>{props.content}</MobileContent>
          )}
        </PageContent>
      )}
      {props.mobileFooter && !props.desktopMode && (
        <PageFooter
          className="shortcuts-footer"
          style={{ padding: '0 16px' }}
        >
          <FooterContent>
            <div>{props.mobileFooter}</div>
          </FooterContent>
        </PageFooter>
      )}
    </PageContainer>
  )
}

export default PageTemplate
