import styled from 'styled-components'
import { breakpoints } from '@clientos/ui-toolkit'

export const PageContainer = styled.div`
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
`
export const PageContent = styled.div`
  .clientos-ui-toolkit-scrollbar.margin-left
    .clientos-ui-toolkit-scrollbar__content {
    padding-right: 16px;
  }
`
export const PageHeader = styled.div`
  margin: 24px 80px 0;
  @media screen and (max-width: 924px) {
    margin: 24px 16px 0;
  }
`
export const PageFooter = styled.div`
  margin-bottom: 24px;
`

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 736px;
    width: 100%;

    @media screen and (max-width: ${breakpoints.md}) {
      max-width: 100%;
    }

    .clientos-ui-toolkit-button-group {
      max-width: 736px;
      width: 100%;

      @media screen and (max-width: ${breakpoints.md}) {
        max-width: 100%;
        margin: 0 0;
        .clientos-ui-toolkit-button__secondary {
          background-color: transparent !important;
        }
      }
    }

    .clientos-ui-toolkit-button-group-vertical {
      @media screen and (max-width: ${breakpoints.md}) {
        row-gap: 16px !important;
      }
    }
  }
`

const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .clientos-ui-toolkit-generic-card {
    max-width: 736px !important;
  }
  .clientos-ui-toolkit-section-list-container__content__header {
    padding-left: 0px !important;
  }
`

export const DesktopContent = styled(MainContent)`
  #destinations-desktop-mode & {
    margin-top: 34px;
  }
`

export const MobileContent = styled(MainContent)`
  .clientos-ui-toolkit-generic-card {
    .clientos-ui-toolkit-generic-card__content__body__padding-horizontal {
      padding-right: 0px !important;
    }
  }
`
